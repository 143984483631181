import React from 'react';
import './AppStore.css';

const AppStore = () => {
  const language = navigator.language.split('-')[0]; // Extracting language code
  const isFrench = language === 'fr'
  
  // Define URLs and alt text for both English and French Canadian versions
  const englishUrl = "https://apps.apple.com/us/app/mnezy/id1513672558?itsct=apps_box_badge&amp;itscg=30200";
  const frenchCanadianUrl = "https://apps.apple.com/ca-fr/app/mnezy/id1513672558?itsct=apps_box_badge&amp;itscg=30200";
  
  const url = isFrench ? frenchCanadianUrl : englishUrl;
  
  const altEnglish = "Download on the App Store";
  const altFrenchCanadian = "Télécharger sur l'App Store";
  
  const alt = isFrench ? altFrenchCanadian : altEnglish;
  
  // Define image URLs for both English and French Canadian versions
  const englishImageUrl = "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1659916800&h=19859a8decfd23b003ce80bcd1de5057";
  const frenchCanadianImageUrl = "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-ca?size=250x83&amp;releaseDate=1659916800&h=19859a8decfd23b003ce80bcd1de5057";
  
  const imageUrl = isFrench ? frenchCanadianImageUrl : englishImageUrl;
  
  return (
    <a href={url}>
      <img src={imageUrl} alt={alt} className="download"/>
    </a>
  );
}

export default AppStore;
