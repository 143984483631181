import React, { useState, useEffect } from "react";
import { BlockTitle } from "konsta/react";
import axios from "axios";

function Preview() {
  // const [iPhoneScreenshots, setIPhoneScreenshots] = useState([]);
  // const [iPadScreenshots, setIPadScreenshots] = useState([]);

  // useEffect(() => {
  //   const fetchAppPreview = async () => {
  //     try {
  //       const iOSResponse = await axios.get(
  //         "https://itunes.apple.com/lookup?bundleId=com.ml.simon&country=ca",
  //         {
  //           headers: {
  //             "Access-Control-Allow-Origin": "*",
  //           },
  //         }
  //       );
  //       if (
  //         iOSResponse.data &&
  //         iOSResponse.data.results &&
  //         iOSResponse.data.results[0]
  //       ) {
  //         if (iOSResponse.data.results[0].screenshotUrls) {
  //           setIPhoneScreenshots(iOSResponse.data.results[0].screenshotUrls);
  //         }

  //         if (iOSResponse.data.results[0].ipadScreenshotUrls) {
  //           setIPadScreenshots(iOSResponse.data.results[0].ipadScreenshotUrls);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchAppPreview();
  // }, []);

  return (
    <div
      style={{
        textAlign: "left",
      }}
    >
      {/* {!!iPhoneScreenshots.length && (
        <>
          <BlockTitle
            large
            style={{
              margin: "20px",
            }}
          >
            iOS
          </BlockTitle>
          <div
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              height: "500px",
              width: "100vw",
              whiteSpace: "nowrap",
            }}
          >
            <div
              id=''
              style={{
                height: "100%",
              }}
            >
              {iPhoneScreenshots.map((screenshot) => (
                <img
                  src={screenshot}
                  alt=''
                  style={{
                    height: "90%",
                    margin: "20px",
                    borderRadius: "35px",
                  }}
                />
              ))}
            </div>
          </div>
        </>
      )}
      {!!iPadScreenshots.length && (
        <>
          <BlockTitle
            large
            style={{
              margin: "20px",
            }}
          >
            iPadOS
          </BlockTitle>
          <div
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              height: "500px",
              width: "100vw",
              whiteSpace: "nowrap",
            }}
          >
            <div
              id=''
              style={{
                height: "100%",
              }}
            >
              {iPadScreenshots.map((screenshot) => (
                <img
                  src={screenshot}
                  alt=''
                  style={{
                    height: "90%",
                    margin: "20px",
                    borderRadius: "35px",
                  }}
                />
              ))}
            </div>
          </div>
        </>
      )} */}
    </div>
  );
}

export default Preview;
