import './Loading.css';
import loader from '../../assets/loader.gif';

const Loading = () => {
  return (
    <img className="loading" src={loader} alt="loading"/>
  );
}

export default Loading;
