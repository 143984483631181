import logo from "./assets/logo.png";
import "./App.css";
import Loading from "./components/Loading/Loading";
import AppStore from "./components/AppStore/AppStore";
import { useTranslation } from "react-i18next";
import Preview from "./components/Preview/Preview";

const App = () => {
  const { t } = useTranslation();
  return (
    <div className='App'>
      <div className='App-body'>
        <h1>{t("app.name")}</h1>
        <img src={logo} className='App-logo' alt='logo' />
        <div>
          <h2>{t("app.description")}</h2>
          <h3>{t("app.platforms")}</h3>
        </div>

        <AppStore />

        <Preview />

        <Loading />
      </div>
    </div>
  );
};

export default App;
